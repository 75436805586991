'use strict';
import {
    PeerConnectionClient
} from "./client"
import control from "./proto/control.js"
//import RecordRTC from "recordrtc"
// Keep this in sync with the HTML element id attributes. Keep it sorted.
var CODE_NUMBER = {
    CodeLButtonDown: 101,
    CodeLButtonUp: 102,
    CodeMouseMove: 103,
    CodeLButtonDBClick: 104,
    CodeRButtonDown: 105,
    CodeRButtonUp: 106,
    CodeKeyDown: 107,
    CodeKeyUp: 108,
    CodeChar: 109,
    CodeMButtonDown: 210,
    CodeMButtonUp: 211,
    CodeMouseWheel: 112,
    CodeDefinition: 116,
    CodeFingerDown: 117,
    CodeFingerUp: 118,
    CodeFingerMove: 115,
    CodeFingersDown: 113,
    CodeFingersUp: 114,
    CodeClipBoard: 132,
    CodeStartGame: 133,
    CodeWeixinPay: 200,
    CodeStartAppCmd: 210,
    CodeConnDisplaced: 136
}

class AppController {
    constructor(loadingParams) {
        this.pcClient_ = null;
        this.offsetX = ""
        this.offsetY = ""
        this.upsetX = ""
        this.upsetY = ""
        this.moveX = ""
        this.moveY = ""
        this.deltaY = ""
        this.keyCode = 0
        this.touches = []
        this.fingers = false
        this.keyCodearr = []
        this.fingervalue = {}
        this.setDefinite = ""
        this.videoWidth = ""
        this.videoHeight = ""
        this.messagedata = {}
        this.stream = null
        this.count = 0
        this.timer = null
        this.ice = "disconnected"
        this.restartinterval = 500
        this.restartcount = 0;
        this.currentRoundTripTime = {
            currentRoundTripTime: ''
        }
        this.framesPerSecond = {
            framesPerSecond: ''
        }
        this.answercode = {
            answercode: '1'
        }
        this.iceconnectionstate = {
            iceconnectionstate: '1'
        }
        this.gamestatus = {
            gamestatus: "2"
        }
        this.resolution = {
            status: '1',
            data: {}
        }
        this.livestatus = {
            livestatus: 'stoplive'
        }
        this.rtmpurl = {
            rtmpurl: 'rtmp'
        }
        this.obsurl = {
            obsurl: 'obsurl'
        }
        this.channelstatus = {
            channelstatus: 'close'
        }
        this.remoteVideo_ = document.querySelector("#remote-video");
        this.timestamp = 0
        this.recorder = null
        this.statstimer = null;
        this.restarttimer = null;
        this.stopped = false;
        this.channelopened = false;
        this.beforeopenkind = 0;
        this.updatekind = 0;
        this.hassended = false;
        this.maincontrol = false;
        this.startTime = null;
        this.localstream = null
        this.usecamera = loadingParams.mediaConstraints.usecamera
        this.usemicrophone = loadingParams.mediaConstraints.usemicrophone

        this.sysInfo = this.getExplore();
        this.loadingParams_ = {
            ...loadingParams,
            peerConnectionConfig: {
                iceServers: [{
                    urls: "turn:turn.wumitech.com:3478",
                    username: "wumitech",
                    credential: "wumitech.com@123",
                }, ],
            },
            debug: true,
        };
        var paramsPromise = Promise.resolve({});
        Promise.resolve(paramsPromise).then(function (newParams) {
            // Merge newly retrieved params with loadingParams.
            if (newParams) {
                Object.keys(newParams).forEach(function (key) {
                    this.loadingParams_[key] = newParams[key];
                }.bind(this));
            }
        }.bind(this)).catch(function (error) {
            this.trace_('Error initializing: ' + error.message);
        }.bind(this));
    }
    setCount() {
        this.count = 1;
    }
    //获取当前浏览器型号
    getExplore() {
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1]:
            (s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
            (s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
            (s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
            (s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
            (s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
            (s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0;
        // 判断浏览器型号
        if (Sys.ie) return ('IE: ' + Sys.ie);
        if (Sys.edge) return ('EDGE: ' + Sys.edge);
        if (Sys.firefox) return ('Firefox: ' + Sys.firefox);
        if (Sys.chrome) return ('Chrome: ' + Sys.chrome);
        if (Sys.opera) return ('Opera: ' + Sys.opera);
        if (Sys.safari) return ('Safari: ' + Sys.safari);
        return 'Unkonwn';
    }
    //开始连接
    startConnection() {
        // If the params has a id specified, we should connect to that room
        // immediately. If not, show the room selection UI.
        if (this.loadingParams_.Id) {
            const mediaConstraints = {
                video: false,
                audio: false
            }
            if (this.usecamera == true && this.usemicrophone == true) {
                mediaConstraints.video = {
                    width: {
                        min: 640,
                        ideal: 1280
                    },
                    height: {
                        min: 480,
                        ideal: 720
                    },
                }
                mediaConstraints.audio = true
                this.getUserMedia(mediaConstraints).then(() => {
                    this.trace_('start to connect: ' + this.loadingParams_.Id);
                    this.startSignaling_();
                    this.timestamp = Date.now();
                })
            } else if (this.usecamera == false && this.usemicrophone == false) {
                this.trace_('start to connect: ' + this.loadingParams_.Id);
                this.startSignaling_();
                this.timestamp = Date.now();
            } else if (this.usecamera == true && this.usemicrophone == false) {
                mediaConstraints.video = {
                    width: {
                        min: 640,
                        ideal: 1280
                    },
                    height: {
                        min: 480,
                        ideal: 720
                    },
                }
                mediaConstraints.audio = false
                this.getUserMedia(mediaConstraints).then(() => {
                    this.trace_('start to connect: ' + this.loadingParams_.Id);
                    this.startSignaling_();
                    this.timestamp = Date.now();
                })
            } else if (this.usecamera == false && this.usemicrophone == true) {
                mediaConstraints.video = false
                mediaConstraints.audio = true
                this.getUserMedia(mediaConstraints).then(() => {
                    this.trace_('start to connect: ' + this.loadingParams_.Id);
                    this.startSignaling_();
                    this.timestamp = Date.now();
                })
            }
        }
    }
    //获取摄像头
    getCamera(val) {
        this.usecamera = true
        this.stopunmanedlive()
        this.reStart()
        // this.pcClient_.getCamera()
    }
    //获取麦克风
    getMicrophone(val) {
        this.usemicrophone = true
        this.reStart()
        // this.pcClient_.getMicrophone()
    }
    closeCamera() {
        this.usecamera = false
        this.reStart()
        // this.pcClient_.closeCamera()
    }
    closeMicrophone() {
        this.usemicrophone = false
        this.reStart()
        // this.pcClient_.closeMicrophone()
    }
    //真人直播获取摄像头、麦克风
    getUserMedia(mediaStreamConstraints) {
        // const mediaStreamConstraints = {
        //     video: {
        //         // width: 1280,
        //         // height: 720,
        //         width: {
        //             min: 640,
        //             ideal: 1280
        //         },
        //         height: {
        //             min: 480,
        //             ideal: 720
        //         },
        //         // aspectRatio: 16 / 9,
        //         // frameRate: { min: 30 },
        //         // advanced: [{
        //         //         width: 1920,
        //         //         height: 1080
        //         //     },
        //         //     {
        //         //         aspectRatio: 16 / 9
        //         //     },
        //         //     {
        //         //         frameRate: {
        //         //             min: 50
        //         //         }
        //         //     },
        //         //     {
        //         //         frameRate: {
        //         //             min: 40
        //         //         }
        //         //     },
        //         // ],
        //     },
        //     audio: true,
        // };
        return new Promise((resolve, reject) => {
            navigator.mediaDevices
                .getUserMedia(mediaStreamConstraints).then((mediaStream) => {
                    this.localstream = mediaStream
                    resolve()
                })
                .catch((err) => {
                    console.log(err.name)
                    resolve()
                })
        })
    }
    reStart() {
        this.trace_("===restart===");
        if (this.stopped) {
            this.trace_("rtc stopped,I don't want to restart.");
            return;
        }
        if (this.restarttimer) {
            clearTimeout(this.restarttimer);
            this.restarttimer = null;
            this.trace_("clear restart timer, ready to restart.");
        }
        this.closeConnection();
        this.restarttimer = setTimeout(() => {
            this.startConnection();
        }, this.restartinterval);
        if (this.restartcount < 3) {
            this.restartcount += 1;
        } else {
            this.restartinterval = 3000; //前3次连接失败后，每隔3秒重连一次
        }
    }
    getLastTimestamp() {
        return this.timestamp;
    }
    createPcClient_() {
        this.pcClient_ = new PeerConnectionClient(this.loadingParams_, this.startTime);
        this.pcClient_.onremotestreamadded = this.onRemoteStreamAdded_.bind(this);
        this.pcClient_.onerror = this.trace_.bind(this);
        this.pcClient_.onchangedefinit = this.onchangedefinit_.bind(this);
        this.pcClient_.onchangestatus = this.onchangestatus.bind(this);
        this.pcClient_.onchangeicestatus = this.onchangeicestatus.bind(this);
        this.pcClient_.onresolutionstatus = this.onresolutionstatus.bind(this);
        this.pcClient_.onmessagehandle = this.onmessagehandle.bind(this);
        this.pcClient_.onchannelstatus = this.onchannelstatus.bind(this);
        this.getpeerstates();
    };
    //answer获取状态
    onchangestatus(answercode) {
        this.answercode.answercode = answercode
    }
    //p2p连接状态
    onchangeicestatus(iceconnectionstate) {
        this.trace_(this.loadingParams_.Id + " " + "ice state: " + iceconnectionstate);
        this.iceconnectionstate.iceconnectionstate = iceconnectionstate
        this.ice = iceconnectionstate
    }
    //切换分辨率状态
    onresolutionstatus(data) {
        //    this.trace_("--recived message is:")
        //   this.trace_(data)
        let resolution = {
            status: data.status || "",
            data: data.data || ""
        }

        var height = data.data.height || ""
        var width = data.data.width || ""
        if (height == 2160 && width == 1080) {
            resolution["id"] = 6
        }
        if (height == 1080 && width == 1920) {
            resolution["id"] = 5
        }
        if (height == 1440 && width == 720) {
            resolution["id"] = 3
        }
        if (height == 720 && width == 1280) {
            resolution["id"] = 2
        }
        if (height == 540 && width == 960) {
            resolution["id"] = 1
        }
        if (height == 960 && width == 480) {
            resolution["id"] = 0
        }
        this.resolution.id = resolution.id
        this.resolution.status = resolution.status
        this.resolution.data = resolution.data
    }
    maybeCreatePcClientAsync_() {
        return new Promise(function (resolve, reject) {
            if (this.pcClient_) {
                resolve();
                return;
            }
            this.createPcClient_();
            resolve();
        }.bind(this));
    };
    startSignaling_() {
        this.trace_('Starting signaling.');
        this.startTime = window.performance.now();
        this.maybeCreatePcClientAsync_()
            .then(function () {
                if (this.localstream) {
                    this.pcClient_.addlocaltrack(this.localstream)
                }
                this.pcClient_.startCreateDataChannel();
                this.pcClient_.startAsCaller(this.loadingParams_.offerOptions);
            }.bind(this))
            .catch(function (e) {
                this.trace_('Create PeerConnection exception: ' + e.message);
            }.bind(this));
    };
    startgamestatus(data) {
        this.trace_(data)
        if (data.error == 0) {
            this.gamestatus.gamestatus = "success"
        } else {
            this.gamestatus.gamestatus = "fail"
        }
    }
    forceconnent() {
        this.pcClient_.forceconnection()
    }
    //监听状态变化
    onstatus(statustype, callback) {
        if (statustype == "devicestatus" && this.answercode.answercode) {
            var lastanswercode = this.answercode.answercode
            Object.defineProperty(this.answercode, "answercode", {
                set: function (val) {
                    var answercode = val
                    if (lastanswercode != answercode) {
                        callback(answercode)
                        lastanswercode = answercode
                    } else {
                        callback(answercode)
                    }
                }
            })
        } else if (statustype == "networkstatus" && this.iceconnectionstate.iceconnectionstate) {
            var laststate = this.iceconnectionstate.iceconnectionstate
            Object.defineProperty(this.iceconnectionstate, "iceconnectionstate", {
                set: function (val) {
                    var iceconnectionstate = val
                    if (laststate != iceconnectionstate) {
                        callback(iceconnectionstate)
                        laststate = iceconnectionstate
                        // this.trace_(iceconnectionstate)
                    } else {
                        callback(iceconnectionstate)
                    }
                }
            })
        } else if (statustype == "resolution" && this.resolution.status) {
            var laststatus = this.resolution.status
            Object.defineProperty(this.resolution, "status", {
                set: function (val) {
                    var status = val
                    if (laststatus != status) {
                        callback(status)
                        laststatus = status
                    } else {
                        callback(status)
                    }
                }
            })
        } else if (statustype == "gamestatus" && this.gamestatus.gamestatus) {
            var laststatus = this.gamestatus.gamestatus
            Object.defineProperty(this.gamestatus, "gamestatus", {
                set: function (val) {
                    var gamestatus = val
                    if (laststatus != gamestatus) {
                        callback(gamestatus)
                        laststatus = gamestatus
                    } else {
                        callback(gamestatus)
                    }
                }
            })
        } else if (statustype == "livestatus" && this.livestatus.livestatus) {
            var laststatus = this.livestatus.livestatus
            Object.defineProperty(this.livestatus, "livestatus", {
                set: function (val) {
                    var livestatus = val
                    if (laststatus != livestatus) {
                        callback(livestatus)
                        laststatus = livestatus
                    } else {
                        callback(livestatus)
                    }
                }
            })
        } else if (statustype == "livertmp" && this.rtmpurl.rtmpurl) {
            var laststatus = this.rtmpurl.rtmpurl
            Object.defineProperty(this.rtmpurl, "rtmpurl", {
                set: function (val) {
                    var rtmpurl = val
                    if (laststatus != rtmpurl) {
                        callback(rtmpurl)
                        laststatus = rtmpurl
                    } else {
                        callback(rtmpurl)
                    }
                }
            })
        } else if (statustype == "obsrtmp" && this.obsurl.obsurl) {
            var laststatus = this.obsurl.obsurl
            Object.defineProperty(this.obsurl, "obsurl", {
                set: function (val) {
                    var obsurl = val
                    if (laststatus != obsurl) {
                        callback(obsurl)
                        laststatus = obsurl
                    } else {
                        callback(obsurl)
                    }
                }
            })
        } else if (statustype == "channelstatus" && this.channelstatus.channelstatus) {
            var laststatus = this.channelstatus.channelstatus
            Object.defineProperty(this.channelstatus, "channelstatus", {
                set: function (val) {
                    var channelstatus = val
                    if (laststatus != channelstatus) {
                        callback(channelstatus)
                        laststatus = channelstatus
                    } else {
                        callback(channelstatus)
                    }
                }
            })
        } else {
            setTimeout(() => {
                this.onstatus(statustype, callback)
            }, 0);
        }
    }
    setmaincontrol(ctrl) {
        this.maincontrol = ctrl;
    }
    onchannelstatus(msg) {
        if (msg == "open") {
            this.channelopened = true;
            this.channelstatus.channelstatus = msg
            // if (!this.hassended) {
            if (this.doUpdateWork(this.updatekind)) {
                this.trace_(this.loadingParams_.Id + " onchannelstatus send start update kind: " + this.updatekind);
            }
            this.hassended = true;
            this.beforeopenkind = 0;
            // }
        } else if (msg == "close") {
            this.channelstatus.channelstatus = msg
        } else {
            this.channelopened = false;
        }
        this.trace_("channel state: " + msg + " " + this.channelopened);
    }
    sendupdatenotice(kind) {
        this.updatekind = kind;
        if (this.channelopened) {
            this.doUpdateWork(this.updatekind);
            this.trace_("sendupdatenotice send start update.");
            this.hassended = true;
        } else { //channel还未打开，等待后续发送
            if (this.beforeopenkind == 1) {
                if (kind == 3) {
                    this.updatekind = 1;
                    this.trace_(this.loadingParams_.Id + " update kind change to " + this.updatekind);
                }
            }
            this.beforeopenkind = kind;
        }
    }
    doUpdateWork(kind) {
        if (this.maincontrol) {
            return 0;
        }
        if (kind == 1) {
            this.onStartUpdate();
        } else if (kind == 2) {
            this.onStopUpdate();
        } else {
            this.trace_("nothing to do.");
        }
        return 1;
    }
    onmessagehandle(msg) {
        console.log(msg)
        this.trace_(msg)
        var message = JSON.parse(msg);
        var code = message.code;
        var data = message.data;
        if (code == 202) {
            var cmd = data.cmd || ""
        }
        if (code == 204) {
            var cmd = data.cmd || ""
        }
        switch (code) {
            case CODE_NUMBER.CodeWeixinPay: //weixin pay
                if (data.type = "clipboard_copy") {
                    this.onWriteClipboard(data.content)
                }
                // var type = data.type;
                // var url = data.uri;
                // this.trace_(type + " " + url);
                // this.sendPayRequest_(url);
                //this.openPayWindow(url, "h5wxpay");
                break;
            case CODE_NUMBER.CodeConnDisplaced:
                this.answercode.answercode = code
                break;
            case 141:
                this.rtmpurl.rtmpurl = data.msg
                break;
            case 145:
                this.obsurl.obsurl = data.msg
                break
            default:
                break;
        }
        switch (cmd) {
            case '0x01':
                this.livestatus.livestatus = 'readylive'
                break;
            case '0x00':
                this.livestatus.livestatus = 'stoplive'
                break;
            case '1':
                this.livestatus.livestatus = 'readylive'
                break;
            case '0':
                this.livestatus.livestatus = 'stoplive'
                break;
            default:
                break;
        }
    }
    startlive(mode) {
        return new Promise((resolve, reject) => {
            if (mode == 1) {
                this.trackabled(mode).then()
            }
            if (mode == 2) {
                console.log(mode)
                this.trackabled(mode).then(() => {

                })
            }
        })
    }
    //开启主点位推流
    startsuqarlive() {
        let msg = {
            code: 141,
            body: {
                audio: 1,
                video: 1,
                // ip:'rtmp://10.213.212.213/live/'
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            console.log(res)
        })
    }
    //停止主点位推流
    stopsuqarlive() {
        let msg = {
            code: 142,
            body: {
                audio: 1,
                video: 1,
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            console.log(res)
        })
    }
    //开启副点位拉流
    startunmanedlive(url) {
        let msg = {
            code: 139,
            body: {
                audio: 1,
                video: 1,
                url
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            console.log(res)
        })
    }
    //停止副点位拉流
    stopunmanedlive() {
        let msg = {
            code: 140,
            body: {
                audio: 1,
                video: 1,
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            console.log(res)
        })
    }
    //获取rtmp推流地址
    startrtmpurl() {
        let msg = {
            code: 145,
            body: {
                audio: 1,
                video: 1,
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            console.log(res)
        })
    }
    //停止rtmp拉流
    stoprtmpurl() {
        let msg = {
            code: 146,
            body: {
                audio: 1,
                video: 1,
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            console.log(res)
        })
    }
    stoplive(mode) {
        return new Promise((resolve, reject) => {
            this.trackenabled(mode)
        })
    }
    trackabled(mode) {
        return this.pcClient_.trackabled(mode)
    }
    trackenabled(mode) {
        return this.pcClient_.trackenabled(mode)
    }
    openPayWindow(url, id) {
        let a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        a.setAttribute("id", id);
        a.setAttribute("rel", "https://www.wdyxgames.com");
        // 防止反复添加      
        if (!document.getElementById(id)) {
            document.body.appendChild(a);
        }
        a.click();
    }
    sendPayRequest_(url) {
        this.trace_("sendPayRequest called.");
        var payUrl = decodeURIComponent(url);
        this.trace_("payUrl: " + payUrl);
        var pos = payUrl.indexOf("?");
        if (pos !== -1) {
            var param = payUrl.substring(pos + 1);
            var params = param.split("&");
            if (params.length === 4) {

                const payParam = {
                    "appId": "wx77d53b84434b9d9a",
                    "timeStamp": (Date.parse(new Date())) / 1000,
                    "nonceStr": this.getKeyValue_(params[2]),
                    "package": params[0],
                    "signType": "MD5",
                    "paySign": this.getKeyValue_(params[3])
                };
                this.trace_("payParam: " + JSON.stringify(payParam));
            }
        }
    }
    getKeyValue_(str) {
        var pos = str.indexOf("=");
        if (pos !== -1) {
            return str.substring(pos + 1);
        }
        return "";
    }
    onchangedefinit_(data = {}) {
        this.messagedata = data
        this.trace_(this.messagedata)
    }
    Hangup() {
        this.stream = null;
        this.loadingParams_.Id = "";
        this.stopped = true;
        this.trace_('Hangup called.');
        if (this.statstimer != null) {
            clearInterval(this.statstimer);
            this.statstimer = null;
            this.trace_("close stats timer interval.")
        }
        if (this.restarttimer != null) {
            clearTimeout(this.restarttimer);
            this.restarttimer = null;
            this.trace_("close restart timer.")
        }

        if (this.pcClient_) {
            this.pcClient_.close();
            this.pcClient_ = null;
        }

        if (this.localstream) {
            for (const track of this.localstream.getTracks()) {
                track.stop()
            }
            this.localstream = null
        }
    }
    closeConnection() {
        this.stream = null
        this.trace_('closeConnection called.');
        if (this.statstimer != null) {
            clearInterval(this.statstimer);
            this.statstimer = null;
            this.trace_("close stats timer interval.")
        }
        if (this.restarttimer != null) {
            clearTimeout(this.restarttimer);
            this.restarttimer = null;
            this.trace_("close restart timer.")
        }
        if (this.pcClient_) {
            this.pcClient_.close();
            this.pcClient_ = null;
            console.log("pcclient null")
        }
        if (this.localstream) {
            for (const track of this.localstream.getTracks()) {
                track.stop()
            }
            this.localstream = null
        }
    }
    onRemoteStreamAdded_(stream) {
        let thisApp = this
        this.stream = stream
        this.restartinterval = 500;
        this.restartcount = 0;
        this.trace_('Remote stream added.');
        if (this.remoteVideo_) {
            this.remoteVideo_.onloadeddata = function onloadeddata() {
                thisApp.videoWidth = this.videoWidth
                thisApp.videoHeight = this.videoHeight
                console.log(this.videoWidth, this.videoHeight)
            }
            this.remoteVideo_.onresize = function resize() {}
        }
    }
    getremotestream() {
        return this.stream
    }
    getpeerstates() {
        var _this = this
        if (this.pcClient_) {
            this.statstimer = setInterval(() => {                                                                                             
                if (this.pcClient_ != null) {
                    this.pcClient_.getPeerConnectionStats(this.getstats.bind(_this));
                }
            }, 1000);
        }
    }
    getstats(state) {
        state.forEach(report => {
            // console.log(report)
            if (report.type == "candidate-pair" && report.currentRoundTripTime) {
                // this.currentRoundTripTime.currentRoundTripTime = report.currentRoundTripTime
            }
            if (report.type == "inbound-rtp" && report.framesPerSecond) {
                // console.log(report)
                // console.log(report.jitterBufferDelay / report.jitterBufferEmittedCount)
                // console.log(report.jitterBufferDelay)
                // console.log(report.jitterBufferEmittedCount)
                this.currentRoundTripTime.currentRoundTripTime = report.jitterBufferDelay / report.jitterBufferEmittedCount
                this.framesPerSecond.framesPerSecond = report.framesPerSecond
            }

        })
    }
    getNetDelay(callback) {
        if (this.currentRoundTripTime.currentRoundTripTime) {
            callback(this.currentRoundTripTime.currentRoundTripTime)
            var lasttime = this.currentRoundTripTime.currentRoundTripTime
            Object.defineProperty(this.currentRoundTripTime, "currentRoundTripTime", {
                set: function (val) {
                    var time = val
                    if (lasttime != time) {
                        callback(time)
                        lasttime = time
                    }
                }
            })
        } else {
            setTimeout(() => {
                this.getNetDelay(callback)
            }, 1000);
        }

    }
    getcurrentfps(callback) {
        if (this.framesPerSecond.framesPerSecond) {
            callback(this.framesPerSecond.framesPerSecond)
            var lasttime = this.framesPerSecond.framesPerSecond
            Object.defineProperty(this.framesPerSecond, "framesPerSecond", {
                set: function (val) {
                    var fps = val
                    if (lasttime != fps) {
                        callback(fps)
                        lasttime = fps
                    }
                }
            })
        } else {
            setTimeout(() => {
                this.getcurrentfps(callback)
            }, 1000)
        }
    }
    //数据交互
    sendMessage(message) {
        if (this.ice !== "connected") {
            return
        }
        this.timestamp = Date.now()
        var msg = {
            name: message.name || "",
            gamename: message.gamename || "",
            jobid: message.jobid || "",
            offsetWidth: message.offsetWidth || "",
            offsetHeight: message.offsetHeight || "",
            offsetX: message.offsetX || "",
            offsetY: message.offsetY || "",
            timeStamp: message.timeStamp || "",
            button: message.button || "",
            buttontype: message.buttontype || '',
            wheelDelta: message.wheelDelta || '',
            keyCode: message.keyCode || "",
            content: message.content || ""
        }
        if (msg.name == 'mousedown') {
            this.onOffsetSend(msg)
        }
        if (msg.name == "mouseup") {
            this.onUpsetSend(msg)
        }
        if (msg.name == "mousemove") {
            this.onMoveSend(msg)
        }
        if (msg.name == "mousewheel" || msg.name == "wheeldown" || msg.name == "wheelup" || msg.name == "touchrightstart" || msg.name == "touchrightend") {
            this.onWheelSend(msg)
        }
        if (msg.name == "keydown" || msg.name == "keyup") {
            this.onkeyCodeSend(msg)
        }
        if (msg.name == "touchstart") {
            this.onTouchDownSend(msg)
        }
        if (msg.name == "touchend") {
            this.onTouchUpSend(msg)
        }
        if (msg.name == "touchmove") {
            this.onTouchMoveSend(msg)
        }
        // if (msg.name == "keytouchstart") {
        //     this.getKeyOffsets(msg)
        // }
        // if (msg.name == "keytouchend") {
        //     this.getKeyOffsets(msg)
        // }
        if (msg.name == "backhome") {
            this.onBackHome(msg)
        }
        if (msg.name == "phonemenu") {
            this.onPhoneMenu(msg)
        }
        if (msg.name == "backlast") {
            this.onBackLast(msg)
        }
        if (msg.name == "addvolume") {
            this.onChangeVolume(msg)
        }
        if (msg.name == "devolume") {
            this.onChangeVolume(msg)
        }
        if (msg.name == 'clipboard') {
            this.onSendClipboard();
        }
        if (msg.name == "startgame") {
            this.onStartGame(msg)
        }
        if (msg.name == "forcetk") {
            this.onRestartTk(msg)
        }
        if (msg.name == "startupdate") { //通知后台开始软编码，前台开始刷新画面
            this.onStartUpdate();
        }
        if (msg.name == "stopupdate") { //通知后台停止软编码，前台停止刷新画面
            this.onStopUpdate();
        }
    }
    onSendChannelData_(msg) {
        if (this.pcClient_ !== null) {
            return this.pcClient_.sendDataMessage(msg);
        } else {
            return new Promise((resolve, reject) => {
                resolve({
                    sendstatus: 'failed',
                    sendmessage: msg
                })
            });
        }
    }
    //const unsigned CD_START_SOFT_ENCODE = 134;
    onStartUpdate() {
        var raw_msg = control.lookup("raw_msg");
        var data = {
            "@type": "wumi.secm",
            v: 2 //fps
        };
        var msg = {
            code: 134,
            msg: data,
        }
        var finnalmsg = raw_msg.encode(raw_msg.fromObject(msg)).finish();
        var id = this.loadingParams_.Id
        this.onSendChannelData_(finnalmsg).then((res) => {
            console.log("开始编码")
            console.log("onStartUpdate: " + id + " " + JSON.stringify(res));
            if (res.sendstatus != "success") {
                this.trace_("onStartUpdate send failed,retry one time." + JSON.stringify(res));
                this.onSendChannelData_(finnalmsg);
            }
        })
    }
    //const unsigned CD_STOP_SOFT_ENCODE = 135;
    onStopUpdate() {
        //var secm = control.lookup("secm");
        var raw_msg = control.lookup("raw_msg");
        var msg = {
            code: 135,
            msg: "",
        }
        // var data = {
        //     v : 1
        // };
        // msg.msg.push(secm.create(data));
        var finnalmsg = raw_msg.encode(raw_msg.create(msg)).finish();
        // this.trace_(raw_msg.decode(finnalmsg));
        //this.trace_(raw_msg.create(msg))
        this.onSendChannelData_(finnalmsg).then((res) => {
            console.log("结束编码")
            this.trace_("onStopUpdate: " + JSON.stringify(res));
        })
    }
    /**  Firefox support condition:
        Enter about:config in navigation bar
        Click "Accept the Risk and Continue"
        Search dom.events.testing.asyncClipboard and set true
     */
    onStartGame(msg) {
        var msg = {
            code: CODE_NUMBER.CodeStartGame,
            body: {
                name: msg.gamename,
                jobid: msg.jobid
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            //this.trace_(res)
        })
    }
    onRestartTk(msg) {
        var msg = {
            code: CODE_NUMBER.CodeStartAppCmd,
            body: {
                cmd: 1001,
                content: msg.content,
                requestId: this.pcClient_.generateUUID_().substring(0, 8)
            }
        }
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            this.trace_(res)
        })
    }
    onSendClipboard() {
        if (navigator.clipboard === undefined) {
            // this.trace_('当前浏览器版本不支持剪切操作，请更换浏览器!!!')
            return;
        }
        navigator.clipboard.readText().then(
            clipText => {
                if (clipText.length > 0) {
                    var msg = {
                        body: {
                            data: clipText
                        }
                    }
                    msg["code"] = CODE_NUMBER.CodeClipBoard;

                    this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
                        // this.trace_(res)
                    })

                }
            });
    }
    onWriteClipboard(value) {
        if (navigator.clipboard === undefined) {
            // this.trace_('当前浏览器版本不支持剪切操作，请更换浏览器!!!')
            return;
        }
        navigator.clipboard.writeText(value).then((text) => {
            console.log(text)
        })
    }

    onOffsetSend(message) {
        var remoteoffsetX = this.videoWidth / this.remoteVideo_.offsetWidth * message.offsetX
        var remoteoffsetY = this.videoHeight / this.remoteVideo_.offsetHeight * message.offsetY
        var msg = {
            body: {
                xpos: remoteoffsetX,
                ypos: remoteoffsetY
            }
        }
        if (message.buttontype == 0) {
            msg["code"] = CODE_NUMBER.CodeLButtonDown
        } else if (message.buttontype == 2) {
            msg["code"] = CODE_NUMBER.CodeRButtonDown
        } else {
            msg["code"] = CODE_NUMBER.CodeMButtonDown
        }
        if (this.stream) {
            this.onSendChannelData_(msg).then((res) => {
                this.trace_(res)
            })
        }
        this.trace_(this.videoWidth, this.videoHeight)
        this.trace_(this.offsetX, this.offsetY)
    }
    onUpsetSend(message) {
        var remoteupsetX = this.videoWidth / this.remoteVideo_.offsetWidth * message.offsetX
        var remoteupsetY = this.videoHeight / this.remoteVideo_.offsetHeight * message.offsetY
        var msg = {
            body: {
                xpos: remoteupsetX,
                ypos: remoteupsetY
            }
        }
        if (message.buttontype == 0) {
            msg["code"] = CODE_NUMBER.CodeLButtonUp
        } else if (message.buttontype == 2) {
            msg["code"] = CODE_NUMBER.CodeRButtonUp
        } else {
            msg["code"] = CODE_NUMBER.CodeMButtonUp
        }
        if (this.stream) {
            this.onSendChannelData_(msg).then((res) => {
                this.trace_(res)
            })
        }
    }
    onMoveSend(message) {
        var remotemovesetX = this.videoWidth / this.remoteVideo_.offsetWidth * message.offsetX
        var remotemovesetY = this.videoHeight / this.remoteVideo_.offsetHeight * message.offsetY
        var msg = {
            body: {
                xpos: remotemovesetX,
                ypos: remotemovesetY,
            }
        }
        msg["code"] = CODE_NUMBER.CodeMouseMove
        if (this.stream) {
            this.onSendChannelData_(msg).then((res) => {})
        }
    }
    onWheelSend(message) {
        var raw_msg = control.lookup("raw_msg")
        var msg = {
            msg: {
                "@type": "wumi.kb",
                v: message.wheelDelta
            },
            timestamp: message.timeStamp * 1000
        }
        if (message.name == "wheeldown" || message.name == "touchrightstart") {
            msg["code"] = CODE_NUMBER.CodeKeyDown
        }
        if (message.name == "wheelup" || message.name == "touchrightend") {
            msg["code"] = CODE_NUMBER.CodeKeyUp
        }
        if (message.name == "mousewheel") {
            msg["code"] = CODE_NUMBER.CodeMouseWheel
        }
        var finnalmsg = raw_msg.encode(raw_msg.fromObject(msg)).finish();
        this.onSendChannelData_(finnalmsg).then((res) => {
            this.trace_(msg)
        })
    }
    onkeyCodeSend(message) {
        var raw_msg = control.lookup("raw_msg")
        var remoteKey = message.keyCode
        var msg = {
            msg: {
                "@type": "wumi.kb",
                v: remoteKey
            },
            timestamp: message.timeStamp * 1000
        }
        if (message.name == "keydown") {
            // this.trace_(message.keyCode + "按下")
            msg["code"] = CODE_NUMBER.CodeKeyDown
        } else {
            // this.trace_(message.keyCode + "抬起")
            msg["code"] = CODE_NUMBER.CodeKeyUp
        }

        var finnalmsg = raw_msg.encode(raw_msg.fromObject(msg)).finish();
        this.onSendChannelData_(finnalmsg).then((res) => {
            // this.trace_(res)
        })
    }
    onTouchDownSend(message) {
        this.touches.push(message)
        var finger = control.lookup("finger")
        var raw_msg = control.lookup("raw_msg")
        var msg = {
            code: 115,
            msg: "",
            motionFs: [],
            timestamp: message.timeStamp * 1000
        }
        var finnalmsg
        this.touches.forEach((item, index) => {
            if (this.remoteVideo_) {
                var data = {
                    fid: Math.abs(item.button),
                    x: item.offsetX / this.remoteVideo_.offsetWidth,
                    y: item.offsetY / this.remoteVideo_.offsetHeight,
                }
                // console.log(data)
            } else {
                var data = {
                    fid: Math.abs(item.button),
                    x: item.offsetX / item.offsetWidth,
                    y: item.offsetY / item.offsetHeight,
                }
                // console.log(data)
            }
            msg.motionFs.push(finger.create(data))
        })
        finnalmsg = raw_msg.encode(raw_msg.create(msg)).finish()
        // console.log(this.loadingParams_.Id + "按下")
        // console.log(raw_msg.decode(finnalmsg))
        //this.trace_(raw_msg.create(msg))
        this.onSendChannelData_(finnalmsg).then((res) => {
            // console.log(this.loadingParams_.Id + "按下")
            // console.log(res)
            // this.trace_(res)
        })
    }
    onTouchUpSend(message) {
        var sliceindex = ""
        this.touches.forEach((item, index) => {
            if (item.button == message.button) {
                sliceindex = index
            }
        })
        this.touches.splice(sliceindex, 1)
        var finger = control.lookup("finger")
        var raw_msg = control.lookup("raw_msg")
        var msg = {
            code: 115,
            msg: "",
            motionFs: [],
            timestamp: message.timeStamp * 1000
        }
        var finnalmsg
        this.touches.length && this.touches.forEach((item, index) => {
            if (this.remoteVideo_) {
                var data = {
                    fid: Math.abs(item.button),
                    x: item.offsetX / this.remoteVideo_.offsetWidth,
                    y: item.offsetY / this.remoteVideo_.offsetHeight,
                }
                // console.log(data)
            } else {
                var data = {
                    fid: Math.abs(item.button),
                    x: item.offsetX / item.offsetWidth,
                    y: item.offsetY / item.offsetHeight,
                }
                // console.log(data)
            }
            msg.motionFs.push(finger.create(data))
        })
        finnalmsg = raw_msg.encode(raw_msg.create(msg)).finish()
        // console.log(this.loadingParams_.Id + "抬起")
        // console.log(raw_msg.decode(finnalmsg))
        //this.trace_(raw_msg.create(msg))
        this.onSendChannelData_(finnalmsg).then((res) => {
            // console.log(this.loadingParams_.Id + "抬起")
            // console.log(res)
        })
    }
    onTouchMoveSend(message) {
        var finger = control.lookup("finger")
        var raw_msg = control.lookup("raw_msg")
        var msg = {
            code: 115,
            msg: "",
            motionFs: [],
            timestamp: message.timeStamp * 1000
        }
        var finnalmsg
        if (this.remoteVideo_) {
            var data = {
                fid: Math.abs(message.button),
                x: message.offsetX / this.remoteVideo_.offsetWidth,
                y: message.offsetY / this.remoteVideo_.offsetHeight,
            }
        } else {
            var data = {
                fid: Math.abs(message.button),
                x: message.offsetX / message.offsetWidth,
                y: message.offsetY / message.offsetHeight,
            }
        }
        msg.motionFs.push(finger.create(data))
        finnalmsg = raw_msg.encode(raw_msg.create(msg)).finish()
        this.onSendChannelData_(finnalmsg).then((res) => {})
    }
    onKeyDownSend(message) {
        try {
            this.keyCodearr && this.keyCodearr.forEach((item) => {
                if (item == message.keyCode) {
                    throw new error
                }
            })
        } catch (error) {
            return
        }
        this.keyCodearr.push(message.keyCode)

        let msg = {}
        var remoteoffsetX = message.x
        var remoteoffsetY = message.y
        let body = {
            xpos: remoteoffsetX,
            ypos: remoteoffsetY,
            type: 'keydown',
            keyCode: message.keyCode
        }
        body["finger-id"] = this.touches.length

        if (message.keyCode == "65" || message.keyCode == "87" || message.keyCode == "83" || message.keyCode == "68") {
            if (this.touches.length) {
                try {
                    this.touches.forEach((item) => {
                        if (item.keyCode !== message.keyCode) {
                            if (item.keyCode == "65" || item.keyCode == "87" || item.keyCode == "83" || item.keyCode == "68") {
                                this.touches.push(body)
                                this.onKeyDownCorSend()
                                throw new error;
                            }
                        }
                    })

                } catch {

                    return
                }

            }
        }
        this.touches.push(body)
        if (this.touches.length > 1) {
            msg["code"] = CODE_NUMBER.CodeFingersDown
        } else {
            msg["code"] = CODE_NUMBER.CodeFingerDown
        }
        msg["body"] = body
        if (this.stream) {
            this.onSendChannelData_(msg).then((res) => {
                //this.trace_(res)
            })
        }
        this.onKeyMoveSend(this.touches)
    }
    onKeyUpSend(message) {
        var sliceindex = ""
        this.keyCodearr && this.keyCodearr.forEach((item, index) => {
            if (item == message.keyCode) {
                sliceindex = index
            }
        })
        this.keyCodearr.splice(sliceindex, 1)

        var msg = {}
        var touches = this.touches
        var restfinger = []
        var remoteoffsetX = message.x
        var remoteoffsetY = message.y
        var body = {
            xpos: remoteoffsetX,
            ypos: remoteoffsetY,
        }
        if (touches.length > 1) {
            msg["code"] = CODE_NUMBER.CodeFingersUp
        } else {
            msg["code"] = CODE_NUMBER.CodeFingerUp
        }
        if (touches.length) {
            touches.forEach((item, index) => {
                if (item.xpos == body.xpos && item.ypos == body.ypos && item.type == "keydown") {
                    body = item
                    msg["body"] = item
                    if (this.stream) {
                        this.onSendChannelData_(msg).then((res) => {
                            //this.trace_(res)
                        })
                    }
                } else if (item.type == "keycordown") {
                    body = item
                    msg["body"] = item
                    if (this.stream) {
                        this.onSendChannelData_(msg).then((res) => {
                            //this.trace_(res)
                        })
                        this.keyCodearr = []
                        // this.trace_(this.keyCodearr)
                    }

                } else {
                    restfinger.push(item)
                }

            })
        }
        this.touches = restfinger
        this.trace_(this.touches)
        if (restfinger.length !== 0) {
            this.onKeyMoveSend(restfinger)
        }


    }
    onKeyDownCorSend() {
        var cortouches = []
        var corfirtouche = {}
        var corsectouche = {}
        var cornewtouch = {}
        this.touches.forEach((item, index) => {
            if (item.keyCode == "65" || item.keyCode == "87" || item.keyCode == "83" || item.keyCode == "68") {
                cortouches.push(item)
            }
        })
        cortouches.forEach((item) => {
            if (item.keyCode == "65" || item.keyCode == "68") {
                corfirtouche = item
            }
            if (item.keyCode == "87" || item.keyCode == "83") {
                corsectouche = item
            }
        })
        cornewtouch.xpos = (corfirtouche.xpos + corsectouche.xpos) / 2
        cornewtouch.ypos = (corfirtouche.ypos + corsectouche.ypos) / 2
        cornewtouch["finger-id"] = 10
        cornewtouch.type = "keycordown"
        this.touches = this.touches.filter(item => {
            !cortouches.includes(item)
        })
        this.touches.push(cornewtouch)

        var msg = {}
        if (this.touches.length > 1) {
            msg["code"] = CODE_NUMBER.CodeFingersDown
        } else {
            msg["code"] = CODE_NUMBER.CodeFingerDown
        }
        msg["body"] = cornewtouch
        if (this.stream) {
            this.onSendChannelData_(msg).then((res) => {
                //this.trace_(res)
            })
        }

    }
    onKeyMoveSend(message) {
        let msg = {}
        msg["code"] = CODE_NUMBER.CodeFingerMove
        var body = {
            count: message.length,
            fingers: []
        }
        if (message.length) {
            message.forEach((item) => {
                let value = {
                    xpos: item.xpos,
                    ypos: item.ypos,
                }
                value["finger-id"] = item["finger-id"]
                body.fingers.push(value)
            })
        }
        msg["body"] = body
        this.onSendChannelData_(msg).then((res) => {
            // this.trace_(res)
        })
    }
    changeResoution(type) {
        var msg = {
            body: {
                set_resolution: type
            }
        }
        msg["code"] = CODE_NUMBER.CodeDefinition
        this.onSendChannelData_(msg).then((res) => {
            //   this.trace_(res)
        })
    }
    onBackHome(message) {
        let msg = {
            body: {
                key: 0x01000090
            }
        }
        msg["code"] = CODE_NUMBER.CodeKeyDown
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            // this.trace_(res)
        })
        let msgsec = {
            body: {
                key: 0x01000090
            }
        }
        msgsec["code"] = CODE_NUMBER.CodeKeyUp
        this.onSendChannelData_(JSON.stringify(msgsec)).then((res) => {
            // this.trace_(res)
        })
    }
    onChangeVolume(message) {
        if (message.name == "addvolume") {
            let msg = {
                body: {
                    key: 0x01000072
                }
            }
            msg["code"] = CODE_NUMBER.CodeKeyDown
            this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
                this.trace_(res)
            })
            msg["code"] = CODE_NUMBER.CodeKeyUp
            this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
                this.trace_(res)
            })
        }
        if (message.name == "devolume") {
            let msg = {
                body: {
                    key: 0x01000070
                }
            }
            msg["code"] = CODE_NUMBER.CodeKeyDown
            this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
                this.trace_(res)
            })
            msg["code"] = CODE_NUMBER.CodeKeyUp
            this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
                this.trace_(res)
            })
        }

    }
    onPhoneMenu(message) {
        let msg = {
            body: {
                key: 0x01010000
            }
        }
        msg["code"] = CODE_NUMBER.CodeKeyDown
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            //  this.trace_(res)
        })
        let msgsec = {
            body: {
                key: 0x01010000
            }
        }
        msgsec["code"] = CODE_NUMBER.CodeKeyUp
        this.onSendChannelData_(JSON.stringify(msgsec)).then((res) => {
            // this.trace_(res)
        })
    }
    onBackLast(message) {
        let msg = {
            body: {
                key: 0x01000061
            }
        }
        msg["code"] = CODE_NUMBER.CodeKeyDown
        this.onSendChannelData_(JSON.stringify(msg)).then((res) => {
            // this.trace_(res)
        })
        let msgsec = {
            body: {
                key: 0x01000061
            }
        }
        msgsec["code"] = CODE_NUMBER.CodeKeyUp
        this.onSendChannelData_(JSON.stringify(msgsec)).then((res) => {
            this.trace_(res)
        })
    }
    trace_(text) {
        if (this.loadingParams_.debug) {
            console.log(text);
        }
    }
}

export default AppController