'use strict';
export function PeerConnectionClient(params, startTime) {
  this.params_ = params;
  this.startTime_ = startTime;
  this.singalUrl = "https://signal-api.wumitech.com/api/v1/";
  // Create an RTCPeerConnection via the polyfill (adapter.js).
  this.pc_ = new RTCPeerConnection(params.peerConnectionConfig, null);

  if (this.params_.mediaConstraints.audio) {
    this.pc_.addTransceiver('audio', {
      direction: 'recvonly'
    })
    this.trace_("only receive audio.");
  }
  if (this.params_.mediaConstraints.video) {
    this.pc_.addTransceiver('video', {
      direction: 'recvonly'
    })
    this.trace_("only receive video.");
  }
  if (this.params_.mediaConstraints.live) {
    this.pc_.addTransceiver('video', {
      direction: 'sendrecv'
    })
    this.pc_.addTransceiver('audio', {
      direction: 'sendrecv'
    })
  }
  this.pc_.ondatachannel = this.onDataChannel_.bind(this)
  this.pc_.onicecandidate = this.onIceCandidate_.bind(this);
  this.pc_.onicecandidateerror = this.onIceCandidateError_.bind(this);
  this.pc_.onicegatheringstatechange = this.onIceGatheringStateChange_.bind(this);
  this.pc_.ontrack = this.onRemoteStreamAdded_.bind(this);
  this.pc_.onremovestream = this.trace_.bind(this, 'Remote stream removed.');
  this.pc_.oniceconnectionstatechange = this.onIceConnectionStateChanged_.bind(this);
  this.channel = null;
  this.hasRemoteSdp_ = false;
  this.messageQueue_ = [];
  this.isInitiator_ = true;
  this.started_ = false;
  this.recivedmessage = {};
  this.serial_ = "";
  this.cadidateQueue_ = [];

  // TODO(jiayl): Replace callbacks with events.
  // Public callbacks. Keep it sorted.
  this.onerror = null;
  this.oniceconnectionstatechange = null;
  this.onremotestreamadded = null;
  this.onchangedefinit = null;
  this.onchangestatus = null;
  this.onchangeicestatus = null;
  this.onresolutionstatus = null;
  this.onmessagehandle = null;
  this.onchannelstatus = null;
  this.localstream = null;
  this.trace_('create peerconnection success.');
};

// Set up audio and video regardless of what devices are present.
// Disable comfort noise for maximum audio quality.
PeerConnectionClient.DEFAULT_SDP_OFFER_OPTIONS_ = {
  offerToReceiveAudio: 1,
  offerToReceiveVideo: 1,
  voiceActivityDetection: false
};

PeerConnectionClient.prototype.startCreateDataChannel = function () {
  this.channel = this.pc_.createDataChannel('uCloudGame', {
    negotiated: false,
    id: 0,
    ordered: false
  })
  this.channel.onopen = () => {
    this.onchannelstatus(this.channel.readyState);
  }
  this.channel.onerror = (ev) => {
    const err = ev.error;
    this.onchannelstatus(`Data Channel '${channel.protocol}' error! ${err.errorDetail} - ${err.message}`);
  }
  this.channel.onclose = () => {
    this.onchannelstatus("close");
  }
}

PeerConnectionClient.prototype.onDataChannel_ = function (event) {
  let channlevent = event.channel
  channlevent.onmessage = ({
    data
  }) => {
    // console.log(data)
    //this.onresolutionstatus(JSON.parse(data))
    this.onmessagehandle(data)
  }
}

PeerConnectionClient.prototype.sendDataMessage = function (msg) {
  return new Promise((resolve, reject) => {
    if (this.channel.readyState == "open") {
      // console.log(msg)
      this.channel.send(msg)
      resolve({
        channelstate: this.channel.readyState,
        sendstatus: 'success',
        sendmessage: msg
      })
    } else {
      // this.trace_("channel readyState is not open !")
      resolve({
        channelstate: this.channel.readyState,
        sendstatus: 'fail',
        sendmessage: msg
      })
    }
  })
}
PeerConnectionClient.prototype.getCamera = function () {
  var localStream = this.localstream
  const videoTracks = localStream.getVideoTracks();
  videoTracks[0].enabled = true
  console.log("开启摄像头")
}
PeerConnectionClient.prototype.getMicrophone = function () {
  var localStream = this.localstream
  const audioTracks = localStream.getAudioTracks();
  audioTracks[0].enabled = true
  console.log("开启麦克风")
}
PeerConnectionClient.prototype.closeCamera = function () {
  var localStream = this.localstream
  const videoTracks = localStream.getVideoTracks();
  videoTracks[0].enabled = false
  console.log(videoTracks)
  console.log("关闭摄像头")
}
PeerConnectionClient.prototype.closeMicrophone = function () {
  var localStream = this.localstream
  const audioTracks = localStream.getAudioTracks();
  audioTracks[0].enabled = false
  console.log("关闭麦克风")
}

PeerConnectionClient.prototype.addlocaltrack = function (mediaStream) {
  console.log(mediaStream)
  this.localstream = mediaStream
  for (const track of this.localstream.getTracks()) {
    // track.enabled = false
    console.log(track)
    this.pc_.addTrack(track, mediaStream)
    console.log("Added local  stream")
  }
  // navigator.mediaDevices
  //   .getUserMedia({
  //     video: false,
  //     audio: true
  //   }).then((streams) => {
  //     var localStream = this.localstream
  //     const videoTracks = localStream.getVideoTracks();
  //     videoTracks.forEach(videoTrack => {
  //       videoTrack.stop();

  //       localStream.removeTrack(videoTrack);
  //     });
  //   })
}

PeerConnectionClient.prototype.trackabled = function (mode) {
  return new Promise((resolve, reject) => {
    if (this.localstream) {
      console.log(this.localstream)
      if (mode == 1 || mode == 2) {
        // navigator.mediaDevices
        //   .getUserMedia({
        //     video: true,
        //     audio: true
        //   }).then((streams) => {
        //     console.log(streams)
        //     // var localStream = this.localstream
        //     // const videostreamTracks = streams.getVideoTracks();
        //     // videostreamTracks[0].enabled = false
        //     // console.log(videostreamTracks)
        //     // this.localstream.addTrack(videostreamTracks[0])
        //     // console.log("开启tracks")
        //   })
        const videoTracks = this.localstream.getVideoTracks()
        const audioTracks = this.localstream.getAudioTracks()
        console.log(videoTracks)
        console.log(audioTracks)
        // videoTracks[0].enabled = true
        // audioTracks[0].enabled = true
      }
      resolve('tracksabled')
    }
  })

}


PeerConnectionClient.prototype.trackenabled = function (mode) {
  return new Promise((resolve, reject) => {
    if (mode == 1 || mode == 2) {
      if (this.localstream) {
        const videoTracks = this.localstream.getVideoTracks()
        const audioTracks = this.localstream.getAudioTracks()
        // videoTracks[0].enabled = false
        // audioTracks[0].enabled = false
      }
    }
    if (mode == 3) {
      let msg = {
        code: 140,
        body: {
          audio: 1,
          video: 1
        }
      }
      this.sendDataMessage(JSON.stringify(msg)).then((res) => {
        console.log(res)
      })
    }
    resolve('tracksenabled')
  })
}

PeerConnectionClient.prototype.startAsCaller = function (offerOptions) {
  if (!this.pc_) {
    return false;
  }

  if (this.started_) {
    return false;
  }

  this.isInitiator_ = true;
  this.started_ = true;
  this.serial_ = this.generateUUID_().substring(0, 8);
  //this.trace_("serial: " + this.serial_);
  this.pc_.createOffer()
    .then(this.setLocalSdpAndNotify_.bind(this))
    .catch(this.onError_.bind(this, 'createOffer'));

  return true;
};

PeerConnectionClient.prototype.close = function () {
  if (!this.pc_) {
    return;
  }
  this.hasRemoteSdp_ = false;
  this.started_ = false;
  if (this.channel != null) {
    this.channel.close();
    this.channel = null;
  }
  if (this.pc_ != null) {
    this.pc_.close();
    this.pc_ = null;
  }
  this.trace_("close peerconnection success.");
};



PeerConnectionClient.prototype.getPeerConnectionStats = function (callback) {
  if (!this.pc_) {
    return;
  }
  const receivers = this.pc_.getReceivers()
  // console.log(receivers)
  receivers.forEach((item, index) => {
    // console.log(item)
    if (item.track.kind == "video") {
      // console.log(item.track.jitterBufferTarget)
      item.getStats().then(callback)
    }
  })
  // this.pc_.getStats(null)
  //   .then(callback);
};

PeerConnectionClient.prototype.forceconnection = function () {
  return new Promise((resolve, reject) => {
    this.pc_.createOffer().then((res) => {
      var message = {
        offer: "Request force connection",
        request_id: this.generateUUID_(),
        phone_id: this.params_.Id,
        serial: this.generateUUID_().substring(0, 8)
      };
      this.postRequest_(
        this.singalUrl + "send_offer_receive_answer", {
          ...message
        }
      ).then((res) => {
        this.analysisAnswer(JSON.parse(res.data.answer))
      }).catch((error) => {
        var answer = {
          code: 10
        }
        this.analysisAnswer(answer)
      })
    })
  })
}

PeerConnectionClient.prototype.setLocalSdpAndNotify_ =
  function (sessionDescription) {
    //this.trace_('local sdp: ' + sessionDescription.sdp);
    this.pc_.setLocalDescription(sessionDescription)
      .then(this.trace_.bind(this, 'Set local description success.'))
      .catch(this.onError_.bind(this, 'setLocalDescription'));

    //发送sdp到信令服务器，同时接受返回的answer
    var message = {
      request_id: this.generateUUID_(),
      phone_id: this.params_.Id,
      offer: sessionDescription.sdp,
      serial: this.serial_
    };
    this.postRequest_(
        this.singalUrl + "send_offer_receive_answer", {
          ...message
        }
      ).then((res) => {
        this.trace_("send offer description success.")
        this.trace_(this.params_.Id + " iceConnectionState: " + this.pc_.iceConnectionState);
        this.trace_("receive answer: " + res.code + res.message)
        if (res.code == 0) {
          this.analysisAnswer(JSON.parse(res.data.answer))
        } else {
          var answer = {
            code: res.code
          }
          this.analysisAnswer(answer)
          this.trace_(res.message_cn)
        }
      })
      .catch(error => {
        var answer = {
          code: 10
        }
        this.analysisAnswer(answer)
      })
  };

PeerConnectionClient.prototype.analysisAnswer = function (answer) {
  if (answer.code) {
    var code = answer.code
    switch (code) {
      case 1026:
        this.onchangestatus(code)
        return;
      case 1003:
        this.onchangestatus(code)
        return;
      case 1008:
        this.onchangestatus(code)
        return;
      case 1001:
        this.onchangestatus(code)
        return;
      case 73002:
        this.onchangestatus(code)
        return
      default:
        this.onchangestatus(code)
        return;
    }
  }
  this.setRemoteSdp_(answer);
};

PeerConnectionClient.prototype.setRemoteSdp_ = function (message) {
  //this.trace_('remote sdp: ' + message.sdp);
  this.pc_.setRemoteDescription(new RTCSessionDescription(message))
    .then(this.onSetRemoteDescriptionSuccess_.bind(this))
    .catch(this.onError_.bind(this, 'setRemoteDescription'));
};

PeerConnectionClient.prototype.onSetRemoteDescriptionSuccess_ = function () {
  this.trace_('Set remote session description success.');
  this.hasRemoteSdp_ = true;
  if (this.cadidateQueue_.length > 0) {
    this.sendCandidates(this.cadidateQueue_);
  }
  //after setremoteanswer getCandidates
  this.getCandidates();
};

PeerConnectionClient.prototype.onIceCandidateError_ = function (event) {
  //this.trace_("ice error code: " + event.errorCode + " error text: " + event.errorText);
  if (event.errorCode >= 300 && event.errorCode <= 699) {
    // STUN 错误在 300-699 范围内。请参阅 RFC 5389，第 15.6 节
    // 获取代码列表。TURN 增加了一些错误代码；看
    // RFC 5766，第 15 节了解详细信息。
  } else if (event.errorCode >= 700 && event.errorCode <= 799) {
    // 无法访问服务器；一个特定的错误编号是
    // 已提供，但尚未指定。
  }
}

PeerConnectionClient.prototype.onIceGatheringStateChange_ = function (event) {
  let connection = event.target;
  this.trace_("ice gather state: " + connection.iceGatheringState);
}

PeerConnectionClient.prototype.onIceCandidate_ = function (event) {
  if (event.candidate) {
    // Eat undesired candidates.
    if (this.filterIceCandidate_(event.candidate)) {
      //if (event.candidate.type !== 'host') {
      let candidatevalue = {
        candidate: event.candidate.candidate,
        sdp_m_line_index: event.candidate.sdpMLineIndex,
        sdp_mid: event.candidate.sdpMid
      }
      //if remote sdp has been set,send candidate directly.
      if (this.hasRemoteSdp_) {
        //this.trace_("send candidate: " + JSON.stringify(candidatevalue));
        this.sendCandidates(candidatevalue);
      } else {
        this.cadidateQueue_.push(candidatevalue);
        //this.trace_("push candidate: " + JSON.stringify(candidatevalue));
      }
    }
  } else {
    this.trace_('End of gather candidates.');
  }
};

PeerConnectionClient.prototype.sendCandidates = function (candidatevalue) {
  if (this.pc_ === null || !this.started_) {
    return;
  }
  let tmpCandidate = candidatevalue;
  if (!Array.isArray(candidatevalue)) {
    tmpCandidate = [candidatevalue];
  }
  var message = {
    request_id: this.generateUUID_(),
    phone_id: this.params_.Id,
    candidates: tmpCandidate,
    serial: this.serial_
  }
  //send candidates
  this.postRequest_(
    this.singalUrl + "send_candidate", {
      ...message
    }
  ).then((res) => {
    if (res.code == 0) {
      this.trace_('send candidates success')
    }
  })
}


PeerConnectionClient.prototype.getCandidates = function () {
  var candtimer = 1;
  this.postRequest_(this.singalUrl + 'receive_candidate', {
    request_id: this.generateUUID_(),
    phone_id: this.params_.Id,
    serial: this.serial_
  }).then((res) => {
    //this.trace_("getCandidates response: " + JSON.stringify(res));
    if (res.code == 0) {
      if (res.data.candidates.length !== 0) {
        var rescandidate = res.data.candidates
        this.trace_(this.params_.Id + " receive candidates success.");
        for (let i = 0; i < rescandidate.length; i++) {
          var ice = {
            sdpMLineIndex: rescandidate[i].sdp_m_line_index,
            candidate: rescandidate[i].candidate,
            sdpMid: rescandidate[i].sdp_mid
          }
          var icevalue = new RTCIceCandidate({
            ...ice
          });
          if (this.pc_) {
            this.pc_.addIceCandidate(icevalue).then(
              this.trace_('Remote candidate added successfully.')
            )
          }
        }
      }
      if (this.pc_ && this.pc_.iceConnectionState !== "connected") {
        // console.log(this.params_.Id + "candtimer" + candtimer)
        if (candtimer >= 20) {
          console.log(this.params_.Id + "candtimer" + candtimer)
          candtimer = 0
          return
        }
        setTimeout(() => {
          if (this.pc_ && this.started_) {
            this.getCandidates()
              ++candtimer
          } else {
            clearTimeout()
          }
        }, 1000)
      } else {
        clearTimeout()
      }
    }
  })
}

PeerConnectionClient.prototype.onIceConnectionStateChanged_ = function () {
  if (!this.pc_ || !this.started_) {
    return;
  }
  this.onchangeicestatus(this.pc_.iceConnectionState)
  if (this.oniceconnectionstatechange) {
    this.oniceconnectionstatechange();
  }
};

// Return false if the candidate should be dropped, true if not.
PeerConnectionClient.prototype.filterIceCandidate_ = function (candidateObj) {
  var candidateStr = candidateObj.candidate;
  var iceCandidateType = candidateStr.split(' ')[7];
  //no support host type
  if (iceCandidateType === 'host' || candidateStr.indexOf('host') !== -1) {
    //this.trace_("drop host type.");
    return false;
  }

  // Always eat TCP candidates. Not needed in this context.
  if (candidateStr.indexOf('tcp') !== -1) {
    return false;
  }

  // If we're trying to eat non-relay candidates, do that.
  if (this.params_.peerConnectionConfig.iceTransports === 'relay' &&
    iceCandidateType !== 'relay') {
    return false;
  }

  return true;
};

PeerConnectionClient.prototype.onRemoteStreamAdded_ = function (event) {
  if (event.streams && event.streams[0]) {
    this.onremotestreamadded(event.streams[0]);
  }
};

PeerConnectionClient.prototype.onError_ = function (tag, error) {
  if (this.onerror) {
    this.onerror(tag + ': ' + error.toString());
  }
};

PeerConnectionClient.prototype.generateUUID_ = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

PeerConnectionClient.prototype.postRequest_ = function (url, data) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("post", url);
    xhr.setRequestHeader("content-type", "application/json");
    xhr.send(JSON.stringify(data));
    //xhr.timeout = 8000;
    xhr.onerror = function () {
      console.log("网络异常")
      reject("网络异常");
    }
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        }
      }
    }
  })
};

PeerConnectionClient.prototype.trace_ = function (text) {
  //非调试模式，不打印日志
  if (!this.params_.debug) {
    return;
  }
  // This function is used for logging.
  if (text[text.length - 1] === '\n') {
    text = text.substring(0, text.length - 1);
  }
  if (window.performance) {
    var now = (window.performance.now() / 1000).toFixed(3);
    console.log(now + ': ' + text);
  } else {
    console.log(text);
  }
};

export function transKeyCode(key) {
  console.log(key)
  var keyCode = key;
  switch (key) {
    case 8: // Backspace
      keyCode = 0x01000003;
      return keyCode;
    case 9: // Tab Tab
      keyCode = 0x01000001;
      return keyCode;
    case 13: //Enter
      keyCode = 0x01000005;
      return keyCode;
    case 16: //Shift
      keyCode = 0x01000020;
      return keyCode;
    case 17: //Control
      keyCode = 0x01000021;
      return keyCode;
    case 18: // Alt
      keyCode = 0x01000023;
      return keyCode;
    case 20: //capslock
      keyCode = 0x01000024;
      return keyCode;
    case 27: //esc
      keyCode = 0x01000000;
      return keyCode;
    case 32:
      keyCode = 0x20;
      return keyCode;
    case 37: // Left
      keyCode = 0x01000012;
      return keyCode;
    case 38: // Up
      keyCode = 0x01000013;
      return keyCode;
    case 39: // Right
      keyCode = 0x01000014;
      return keyCode;
    case 40: // Down
      keyCode = 0x01000015;
      return keyCode;
    case 45: // Insert
      keyCode = 0x01000006;
      return keyCode;
    case 46: //Delete
      keyCode = 0x01000007;
      return keyCode;

    case 96: // kp 0
      keyCode = 48
      return keyCode;
    case 97: // kp 1
      keyCode = 49
      return keyCode;
    case 98: // kp 2
      keyCode = 50
      return keyCode;
    case 99: // kp 3
      keyCode = 51
      return keyCode;
    case 100: // kp 4
      keyCode = 52
      return keyCode;
    case 101: // kp 5
      keyCode = 53
      return keyCode;
    case 102: // kp 6
      keyCode = 54
      return keyCode;
    case 103: // kp 7
      keyCode = 55
      return keyCode;
    case 104: // kp 8
      keyCode = 56
      return keyCode;
    case 105: // kp 9
      keyCode = 57
      return keyCode;

    case 106: // kp *
      keyCode = 0x62
      return keyCode;
    case 107: // kp +
      keyCode = 0x64
      return keyCode;
    case 108: // kp enter
      keyCode = 0x65
      return keyCode;
    case 109: // kp -
      keyCode = 0x63
      return keyCode;
    case 110: // kp .
      keyCode = 0x2e
      return keyCode;
    case 111: // kp /
      keyCode = 0x61
      return keyCode

      // case 96: // kp 0
      //     keyCode = 0x67
      //     return keyCode;
      // case 97: // kp 1
      //     keyCode = 0x68
      //     return keyCode;
      // case 98: // kp 2
      //     keyCode = 0x69
      //     return keyCode;
      // case 99: // kp 3
      //     keyCode = 0x6a
      //     return keyCode;
      // case 100: // kp 4
      //     keyCode = 0x6b
      //     return keyCode;
      // case 101: // kp 5
      //     keyCode = 0x6c
      //     return keyCode;
      // case 102: // kp 6
      //     keyCode = 0x6d
      //     return keyCode;
      // case 103: // kp 7
      //     keyCode = 0x6e
      //     return keyCode;
      // case 104: // kp 8
      //     keyCode = 0x6f
      //     return keyCode;
      // case 105: // kp 9
      //     keyCode = 0x70
      //     return keyCode;
      // case 106: // kp *
      //     keyCode = 0x62
      //     return keyCode;
      // case 107: // kp +
      //     keyCode = 0x64
      //     return keyCode;
      // case 108: // kp enter
      //     keyCode = 0x65
      //     return keyCode;
      // case 109: // kp -
      //     keyCode = 0x63
      //     return keyCode;
      // case 110: // kp .
      //     keyCode = 0x66
      //     return keyCode;
      // case 111: // kp /
      //     keyCode = 0x61
      //     return keyCode

    case 112: //f1
      keyCode = 0x01000030;
      return keyCode;
    case 113: //f2
      keyCode = 0x01000031;
      return keyCode;
    case 114: //f3
      keyCode = 0x01000032;
      return keyCode;
    case 115: //f4
      keyCode = 0x01000033;
      return keyCode;
    case 116: //f5
      keyCode = 0x01000034;
      return keyCode;
    case 117: //f6
      keyCode = 0x01000035;
      return keyCode;
    case 118: //f7
      keyCode = 0x01000036;
      return keyCode;
    case 119: //f8
      keyCode = 0x01000037;
      return keyCode;
    case 120: //f9
      keyCode = 0x01000038;
      return keyCode;
    case 121: //f10
      keyCode = 0x01000039;
      return keyCode;
    case 122: //f11
      keyCode = 0x0100003a;
      return keyCode;
    case 123: //f12
      keyCode = 0x0100003b;
      return keyCode;
    case 144: //kp numlock
      keyCode = 0x01000025;
      return keyCode;
    case 186: // ;
      keyCode = 0x3b
      return keyCode;
    case 187: // =
      keyCode = 0x3d
      return keyCode
    case 188: //,
      keyCode = 0x2c
      return keyCode;
    case 189: // -
      keyCode = 0x2d
      return keyCode
    case 190: //.
      keyCode = 0x2e
      return keyCode;
    case 191: // /
      keyCode = 0x2f
      return keyCode;
    case 192: // ~
      keyCode = 0x60
      return keyCode
    case 219: //[
      keyCode = 0x5b
      return keyCode
    case 220: // \
      keyCode = 0x5c
      return keyCode
    case 221: // ]
      keyCode = 0x5d
      return keyCode
    case 222: //'
      keyCode = 0x27
      return keyCode

  }
  return keyCode;
}